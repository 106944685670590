import './index.scss';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import * as emailjs from 'emailjs-com';

import MetaForm from './MetaForm';
// import DateAndTime from './DateAndTime';
import Result from './Result';

class ConsultationBooker extends React.Component {
    state = {
        meta: null,
        date: null,
        time: null,
        timezone: null,
        consultationLength: null
    };

    componentDidMount() {
        document.body.className = 'background-consultation';
    }

    metaSubmitted = (params) => {
        emailjs.send("gmail", "consultation_form", params, "user_aPvfayzSNVfDW3sZCLTt2")
            .then(() => {
                this.setState({ meta: params });
            }, (err) => {
                console.log('FAILED...', err);
            })
    }

    dateTimeSubmitted = (params) => {
        this.setState({ date: params.date, time: params.time, consultationLength: params.consultationLength, timezone: params.timezone });
    }

    renderForm() {
        if (!this.state.meta) {
            return <MetaForm onSubmit={this.metaSubmitted} />;
            /*} else if (!this.state.date) {
                return <DateAndTime onSubmit={this.dateTimeSubmitted} />*/
        } else {
            return <Result name={this.state.meta.name} email={this.state.meta.email} company={this.state.meta.company} phone={this.state.meta.phone} date={this.state.date} time={this.state.time} timezone={this.state.timezone} />
        }
    }

    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5">
                <Row>
                    <Col xs="12">
                        {this.renderForm()}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ConsultationBooker;