import './index.scss';
import React from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';

class ConsultationBanner extends React.Component {
    render() {
        return (
            <div className="background-accent ml-0 mr-0 p-3 p-lg-5 mt-5 intro consultation-banner">
                <Row className="pt-5 mx-auto">
                    <h2 className="mx-auto font-weight-bold text-center">Let's work together!</h2>
                </Row>
                <Row className="pt-5 pb-5 mx-auto">
                    <Link className="mx-auto btn text-white btn-intro" to="/consultation">Book a Free Consultation Now!</Link>
                </Row>
            </div>
        );
    }
}

export default ConsultationBanner;