import "./index.scss";
import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Case1 from './Case1';
import Case2 from './Case2';
import Case3 from "./Case3";

class CaseStudy extends React.Component {
    componentDidMount() {
        document.body.className = "body-case-study";
    }

    render() {
        const { id } = this.props.match.params || 0;

        if (id === '1') {
            return <Case1 />;
        } else if (id === '2') {
            return <Case2 />;
        }
        else if (id === '3') {
            return <Case3 />;
        }
        else {
            return <Redirect to="/home" />;
        }
    }
}

export default withRouter(CaseStudy);