import React, { Component } from "react";
import Map from "ol/Map";
import Feature from "ol/Feature";
import View from "ol/View";
import Tile from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import VectorSource from "ol/source/Vector";

export default class OpenLayersMaps extends Component {
  componentDidMount() {
    var baseMapLayer = new Tile({
      source: new OSM()
    });
    var map = new Map({
      target: "map",
      layers: [baseMapLayer],
      view: new View({
        center: fromLonLat([17.200462, 44.759831]),
        maxZoom: 16.5,
        minZoom: 5,
        zoom: 18
      })
    });

    var marker = new Feature({
      geometry: new Point(fromLonLat([17.200462, 44.759831]))
    });
    var vectorSource = new VectorSource({
      features: [marker]
    });
    var markerVectorLayer = new VectorLayer({
      source: vectorSource
    });
    marker.setStyle(
      new Style({
        image: new Icon({
          anchor: [12, 37],
          anchorXUnits: "pixels", //'fraction'
          anchorYUnits: "pixels",
          opacity: 0.8,
          src: "images/maps/softlife-mapIcon.png"
        })
      })
    );

    map.addLayer(markerVectorLayer);
  }
  render() {
    return <div id="map" style={{ height: "100%" }} />;
  }
}
