import "./index.scss";
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import TestimonialsSlider from "../AboutUs/TestimonialsSlider";

class WhatWeDo extends React.Component {
    componentDidMount() {
        document.body.className = "body-wwd";
    }

    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5 text-dark-blue">
                <Row>
                    <Col xs="12" sm="12" lg="10" xl="10">
                        <h2 className="display-4 animated slideInLeft">Transforming ideas.</h2>
                        <h2 className="display-4 pb-4 text-light-blue animated slideInLeft">Digitally.</h2>
                        <h4 className="pt-3 pt-md-5 animated slideInLeft">Seeing our clients passionate about their idea makes us excited. We always look for innovative ways to provide solutions and create better products.</h4>
                    </Col>
                </Row>
                <Row className="pt-5">
                    <Col xs="12" sm="12" md="8" lg="8" xl="8">
                        <Fade bottom>
                            <h2 className="display-5 font-weight-bold">Mobile application development</h2>
                            <Col xs="12" sm="12" md="6" className="pl-0 pt-2 pt-lg-5">
                                <h4 className="text-light-blue">Great ideas brought to a small screen.</h4>
                            </Col>
                            <Col xs={{ size: 8, offset: 2 }} className="show-phone pt-4">
                                <img src="/images/mobile.svg" alt="mobile" />
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Business strategy</h5>
                                <h5 className="show-normal">We always start with a through analysis. We need to make sure that we understand your business idea and that your app will be tailored to the needs of its future users.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Beautiful design</h5>
                                <h5 className="show-normal">It is very difficult to fit big projects into little screens but we know exactly how to do it. We use React Native and most popular libraries, making your app a real piece of mobile art.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Universal interface</h5>
                                <h5 className="show-normal">Our UX/UI designers are always ready to create intuitive and universal interfaces. Your new app will look great on every smartphone and tablet, regardless of its brand and screen size.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Native technologies</h5>
                                <h5 className="show-normal">You want your app's performance to be flawless. To make use of the full power of modern mobile devices, we develop native iOS and Android products - not half-measure hybrid apps.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="show-phone pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Quality assurance</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="show-phone pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Smooth launch and support</h5>
                            </Col>
                        </Fade>
                    </Col>
                    <Col md="4" lg="4" xl="4" className="show-normal my-auto pl-0 pr-0">
                        <Fade bottom>
                            <img src="/images/mobile.svg" alt="mobile" className="pt-5" />
                        </Fade>
                    </Col>
                </Row>
                <Row className="pt-5 mt-md-5">
                    <Col md="6" lg="4" xl="4" className="show-normal my-auto pl-0 pr-5">
                        <Fade bottom>
                            <img src="/images/web_laptop.svg" alt="laptop" className="pt-5" />
                        </Fade>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="8" xl="8">
                        <Fade bottom>
                            <h2 className="display-5 font-weight-bold pl-md-5">Web development</h2>
                            <Col xs="12" sm="12" md="12" className="pl-0 pl-md-5 pt-2 pt-lg-5">
                                <h4 className="text-light-blue">Our web development process is geared toward an intuitive experience for users. We create products that are both aesthetically pleasing and user friendly.</h4>
                            </Col>
                            <Col xs={{ size: 8, offset: 2 }} className="show-phone pt-4">
                                <img src="/images/web_laptop.svg" alt="mobile" />
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pl-0 pl-md-5 pt-4">
                                <h5>We solve problems of all shapes and sizes. We have the benefit of working alongside our mobile app team to create seamless mobile-first experiences, while delivering fully-featured web apps with our team of full-stack web developers. We're experienced in front-end development, building backend infrastructure, API design and development, and strategy advising. We offer comprehensive solutions to fit the needs of our clients.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pl-0 pl-md-5 pt-4">
                                <h5>We employ a wealth of tools and ears of product experience to choose the right tech to achieve the goals of each project.</h5>
                            </Col>
                        </Fade>
                    </Col>
                </Row>
                <Row className="pt-5 mt-md-5">
                    <Col xs="12" sm="12" md="8" lg="8" xl="8">
                        <Fade bottom>
                            <h2 className="display-5 font-weight-bold">Quality assurance</h2>
                            <Col xs="12" sm="12" md="6" className="pl-0 pt-2 pt-lg-5">
                                <h4 className="text-light-blue">Perfect product with perfect reliability.</h4>
                            </Col>
                            <Col xs={{ size: 8, offset: 2 }} className="show-phone pt-4">
                                <img src="/images/qa.svg" alt="qa" />
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-4 pt-lg-4">
                                <h5>Before you show your app to the world, we make sure that it is free of bugs. In our teams we have experienced QA specialists who perform both manual and automatic tests.</h5>
                            </Col>
                            <Col xs="12" sm="12" md="10" lg="10" xl="10" className="pl-0 pt-2 pt-lg-4">
                                <h5 className="font-weight-bold">Smooth launch and support</h5>
                                <h5>When the app is ready, we help you submit it to the market, gather feedback from users and analyse the stats. We also offer further support and maintenance - your product will always stay up to date.</h5>
                            </Col>
                        </Fade>
                    </Col>
                    <Col md="4" lg="4" xl="4" className="show-normal my-auto">
                        <Fade bottom>
                            <img src="/images/qa.svg" alt="qa" />
                        </Fade>
                    </Col>
                </Row>
                <Row className="pt-5 mt-md-5 pl-3 pb-5">
                    <Fade bottom>
                        <h2 className="display-5 font-weight-bold">Technologies</h2>
                    </Fade>
                </Row>
                <Row className="technologies pt-md-5">
                    <Fade bottom>
                        <h4 className="font-weight-lite pl-3">Web development</h4>
                    </Fade>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pt-5">
                        <Row className="align-middle">
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/html-5BW.svg" alt="html5" id="html" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/javascriptBW.svg" alt="js" id="js" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/css-3BW.svg" alt="css3" id="css3" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/reactBW.svg" alt="react" id="react" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/angular-BW.svg" alt="angular" id="angular" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/javaBW.svg" alt="java" id="java" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/phpBW.svg" alt="php" id="php" />
                                </Fade>
                            </Col>
                            <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                <Fade bottom>
                                    <img src="/images/technologies/grey/laravelBW.svg" alt="laravel" id="laravel" />
                                </Fade>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="technologies pt-5 mt-md-5">
                    <Fade bottom>
                        <h4 className="font-weight-lite pl-3">Mobile development</h4>
                    </Fade>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pt-5">
                        <Row>
                            <Fade bottom>
                                <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                    <img src="/images/technologies/grey/javaBW.svg" alt="java" id="java-mob" />
                                </Col>
                            </Fade>
                            <Fade bottom>
                                <Col xs="4" md="3" lg="2" xl="2" className="pl-0 pb-5">
                                    <img src="/images/technologies/grey/reactNativeBW.svg" alt="reactNative" id="react-native" />
                                </Col>
                            </Fade>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-5 mt-3 overflow-hidden pb-lg-5">
                    <Col xs="12" sm="12" md="10" lg="12" xl="12" className="pt-2 pt-lg-4">
                        <Fade bottom>
                            <h2 className="mb-5 mb-lg-0 pb-5 display-4 font-weight-bold text-dark-blue">Testimonials</h2>
                        </Fade>
                        <TestimonialsSlider />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default WhatWeDo;