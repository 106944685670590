import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Project1 from './Project1';
import Project2 from './Project2';

class Project extends React.Component {
    render() {
        const { id } = this.props.match.params || 0;

        if (id === '1') {
            return <Project1 />;
        } else if (id === '2') {
            return <Project2 />;
        } else {
            return <Redirect to="/home" />;
        }
    }
}

export default withRouter(Project);