import "./index.scss";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import * as emailjs from "emailjs-com";
import OpenLayersMaps from "./OpenLayersMaps";
import ContactForm from "./ContactForm";

class Contact extends React.Component {
  componentDidMount() {
    document.body.className = "body-contact";
  }

  onFormSubmit = params => {
    emailjs
      .send("gmail", "contact_form", params, "user_aPvfayzSNVfDW3sZCLTt2")
      .then(
        () => {
          console.log("MAIL SENT");
        },
        err => {
          console.log("FAILED...", err);
        }
      );
  };

  render() {
    return (
      <Container className="pt-3 pt-md-5 mt-md-5">
        <Row className="text-white pb-5 mb-5">
          <Col xs="12" sm="12" lg="6" xl="6" className="animated slideInLeft">
            <h2 className="pb-5 display-4 font-weight-bold">Let's talk</h2>
            <h4 className="font-weight-light pb-4">
              We partner with you to best understand your business objectives
              and a functionalities to maximize results.
            </h4>
            <h4 className="font-weight-light">
              The more we know, the better we will help your goals and dreams.
              Our team will be in touch soon.{" "}
            </h4>
          </Col>
          <Col
            xs={{ size: 8, offset: 2 }}
            sm={{ size: 6, offset: 3 }}
            md={{ size: 5, offset: 4 }}
            lg={{ size: 4, offset: 2 }}
            xl={{ size: 4, offset: 2 }}
            className="pt-5 pt-lg-2 animated slideInRight"
          >
            <img src="/images/contact_mail.svg" alt="post" />
          </Col>
        </Row>
        <Row className="animated slideInUp">
          <Col xs="12" sm="12" lg="6" xl="6">
            <div className="contact-information-wrapper contact-tile p-5">
              <h3 className="mb-4">Contact information</h3>

              <span className="d-block">Address: Jug Bogdana 4</span>
              <span className="d-block">78000, Banja Luka,</span>
              <span className="d-block">Bosnia and Herzegovina</span>
              {/* <span className="d-block">Tel: +387 51 000 000</span> */}
              <span className="d-block">Cell: +387 65 764252</span>
              <span className="d-block">E-mail: info@softlife.io</span>
            </div>
          </Col>
          <Col xs="12" sm="12" lg="6" xl="6" className="pt-5 pt-lg-0">
            <div className="contact-tile">
              <OpenLayersMaps />
            </div>
          </Col>
        </Row>
        <Row className="pt-5 mt-5">
          <Col xs="12" sm="12" lg="10" xl="10">
            <Fade bottom>
              <h2 className="pb-5 display-4 font-weight-bold text-dark-blue">
                Send us message
              </h2>
              <ContactForm onSubmit={this.onFormSubmit} />
            </Fade>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;
