import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

class Case3 extends React.Component {
    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5 text-dark-blue case-wrapper">
                <Row>
                    <Col xs="12" sm="12" lg="12" xl="12">
                        <h2 className="display-4 font-weight-bold animated slideInLeft">eClipLive</h2>
                        <h5 className="pt-3 pt-md-5 animated slideInLeft">Building eClipLive required creating an elaborate system able to communicate with multiple databases at all times.</h5>
                        <h5 className="pt-3 animated slideInLeft">The sistem gathers and displays current and accurate data from an ever-growing set of information on the current media movements, news and information in international media, customised to the user’s preferences and subscribed options.</h5>
                        <h5 className="pt-3 animated slideInLeft">Such flexibility is achieved through the use of cutting-edge JavaScript on the frontend based on React, while the backend is constructed in Spring, querying simultaneously multiple databases (Oracle, MySQL), offering a seamless and responsive experience to the end-user.</h5>
                        <h5 className="pt-3 animated slideInLeft">The project is in current development, to be deployed and used on top of the already vast eClip system used in multiple European countries.</h5>
                        <div className={`mr-5 pt-5 menu-item text-light-blue animated slideInLeft`}><h5 className="font-weight-bold">The project is currently in development</h5></div>
                    </Col>
                </Row>
                <Row>
                    <Fade bottom>
                        <Col xs="12" sm="12" md="12" className="mt-5 pt-5">
                            <img src="/cases/case3/first.png" alt="first" className="shadow" />
                        </Col>
                        <Row className="p-3">
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case3/second.png" alt="second" className="shadow" />
                            </Col>
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case3/third.png" alt="third" className="shadow" />
                            </Col>
                        </Row>
                        <Row className="p-3">
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case3/fourth.png" alt="fourth" className="shadow" />
                            </Col>
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case3/fifth.png" alt="fifth" className="shadow" />
                            </Col>
                        </Row>
                    </Fade>
                </Row>
            </Container>
        );
    }
}

export default Case3;