import React from 'react';
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Input } from 'reactstrap';
import Fade from 'react-reveal/Fade';

class MetaForm extends React.Component {
    state = {
        name: "",
        email: "",
        phone: "",
        company: "",
    };


    clearState = () => {
        this.setState({
            name: "",
            email: "",
            phone: "",
            company: "",
        });
    };

    handleSubmit = (evt) => {
        evt.preventDefault();

        this.props.onSubmit(this.state);
        this.clearState();
    }

    render() {
        const { name, email, phone, company } = this.state;

        return (
            <Fade bottom>
                <h2 className="pb-5 display-4 font-weight-bold text-dark-blue">Book a free consultation</h2>
                <Form onSubmit={this.handleSubmit} className="contact-form">
                    <FormGroup row className="mb-4">
                        <Col sm="12" md="8">
                            <Input type="text" name="name" id="name" placeholder="Full Name" maxLength="70" value={name} onChange={({ target }) => this.setState({ name: target.value })} required />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Col sm="12" md="8">
                            <Input type="email" name="email" id="email" placeholder="E-mail" maxLength="50" value={email} onChange={({ target }) => this.setState({ email: target.value })} required />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Col sm="12" md="8">
                            <Input type="phone" name="phone" id="phone" placeholder="Phone Number" maxLength="50" value={phone} onChange={({ target }) => this.setState({ phone: target.value })} required />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Col sm="12" md="8">
                            <Input type="text" name="company" id="company" placeholder="Company" maxLength="50" rows="11" value={company} onChange={({ target }) => this.setState({ company: target.value })} required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm="12" md="3">
                            <Button type="submit" className="btn-submit">Book now</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </Fade>
        );
    }
}

MetaForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}


export default MetaForm;