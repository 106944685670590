import "./index.scss";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

import TeamMembersSlider from "./TeamMembersSlider";

class AboutUs extends React.Component {
  componentDidMount() {
    document.body.className = "body-about-us";
  }

  render() {
    return (
      <>
        <div className="body-about-background m-0 p-0 animated slideInDown fast"></div>
        <Container className="pt-3 pt-md-5 mt-md-5">
          <Row>
            <Col
              xs="12"
              sm="10"
              md="5"
              lg="5"
              xl="5"
              className="my-auto text-white pl-md-0 animated fadeIn"
            >
              <h2 className="display-4 header-text font-weight-bold">
                About Us
              </h2>
              <h4 className="pt-3 about-intro-text">
                Welcome to the place where most beautiful solutions are being
                made.
              </h4>
            </Col>
            <Col
              xs={{ size: 8, offset: 2 }}
              sm={{ size: 8, offset: 2 }}
              md={{ size: 7, offset: 0 }}
              lg="7"
              xl="7"
              className="pr-0 pt-5 animated slideInRight"
            >
              <img src="/images/aboutUs.svg" alt="about-us" />
            </Col>
          </Row>
          <Row className="pt-5 mt-xl-5 animated fadeIn slow">
            <Col xs="11" sm="10" md="6" className="mt-md-5 mt-lg-4 mt-xl-5">
              <img src="/images/scroll-down.svg" alt="scroll" />
              <span className="pl-3 text-dark-blue">
                Scroll to see few words about us
              </span>
            </Col>
          </Row>
          <Row className="pt-5 mt-5 text-dark-blue">
            <Fade bottom>
              <Col xs="12">
                <h5>
                  <strong>Softlife</strong> is a high-quality software
                  development agency co-founded by four friends that have one
                  simple goal, improving lives and businesses through cutting
                  edge software solutions. We build your desired product and
                  make sure to become your trusted long-term partner. We are a
                  young, talented and purpose-driven team, united in the goal of
                  making the finest products for our clients.
                </h5>
                <h5 className="pt-3">
                  We operate from beautiful city of Banja Luka, Bosnia and
                  herzegovina. Our technical team is led b outstanding
                  developers with ears of experience in the industry. We are
                  young, talented and purpose driven team, united in the goal of
                  making the finest products for you.
                </h5>
                <h5 className="pt-3">
                  Our success is measured by your happiness, we prosper when you
                  do.
                </h5>
              </Col>
            </Fade>
          </Row>
          <Row className="pt-md-5 mt-3">
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              className="pt-2 pt-lg-4"
            >
              <Fade bottom>
                <h2 className="pb-5 display-4 font-weight-bold text-dark-blue">
                  Our Guiding Principles
                </h2>
              </Fade>
              <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pt-5">
                <Row className="principles align-middle">
                  <Col
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    className="pl-0 pt-3 pt-md-0 pl-0"
                  >
                    <div className="text-center float-left principle-wrapper">
                      <Fade bottom>
                        <img
                          src="/images/principles/quality.svg"
                          alt="quality"
                        />
                        <h4 className="text-accent-2 font-weight-bold pt-3">
                          Quality
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                  <Col
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    className="pt-3 pt-md-0 pl-0"
                  >
                    <div className="text-center float-left-normal principle-wrapper">
                      <Fade bottom>
                        <img
                          src="/images/principles/transparency.svg"
                          alt="transparency"
                        />
                        <h4 className="text-accent-2 font-weight-bold pt-3">
                          Transparency
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                  <Col
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    className="pt-3 pt-md-0 pl-0"
                  >
                    <div className="text-center float-left principle-wrapper">
                      <Fade bottom>
                        <img
                          src="/images/principles/communication.svg"
                          alt="communication"
                        />
                        <h4 className="text-accent-2 font-weight-bold pt-3">
                          Communication
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                  <Col
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    className="pt-3 pt-md-0 pl-0"
                  >
                    <div className="text-center float-left-normal principle-wrapper">
                      <Fade bottom>
                        <img
                          src="/images/principles/relationship.svg"
                          alt="relationship"
                        />
                        <h4 className="text-accent-2 font-weight-bold pt-3">
                          Relationship
                        </h4>
                      </Fade>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
          <Row className="pt-5 mt-3 text-dark-blue">
            <Fade bottom>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="pt-2 pt-lg-4"
              >
                <h2 className="pb-5 display-4 font-weight-bold text-dark-blue">
                  What makes us special
                </h2>
                <h5>
                  Development is a two-way road and working together with you we
                  focus on creating the most beautiful products. That is a
                  promise of our outstanding software developers who have years
                  of experience in the industry, designing and developing
                  solutions for big enterprises, SMEs and startups. Our passion
                  for creating innovative solutions ensures that you always get
                  a superior product. Simply put, we strive to make your dreams
                  come true.
                </h5>
                <h5 className="pt-3">
                  We believe in Agile software development, which makes sure
                  that our clients are active participants in the development
                  process, always knowing at what stage their project is. At our
                  development center, we make sure that project management is
                  always transparent with the help of Jira and Trello. We value
                  open and frequent communication with our clients, seeing it as
                  a fundamental ingredient of the development process. The more,
                  the better. Every client is entitled to a development team,
                  tailor-made based on the project requirements. This includes
                  developers, designers, project manager, and QA team. This
                  gives us the ability to run the whole project or become part
                  of the existing team.
                </h5>
              </Col>
            </Fade>
          </Row>
          <Row className="pt-5 mt-3 overflow-hidden pb-lg-5">
            <Col
              xs="12"
              sm="12"
              md="10"
              lg="12"
              xl="12"
              className="pt-2 pt-lg-4"
            >
              <Fade bottom>
                <h2 className="mb-5 mb-lg-0 pb-5 display-4 font-weight-bold text-dark-blue">
                  Meet our team
                </h2>
              </Fade>
              <TeamMembersSlider />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AboutUs;
