import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const Result = ({ name, email, company, phone, date, time, timezone }) => {
    return (
        <Fade bottom>
            <h1 className="pb-3 font-weight-bold text-dark-blue">Thank you for showing interest in working with us!</h1>
            <h3 className="pb-5 font-weight-bold text-dark-blue">Expect to hear from us soon.</h3>
            <h4 className="text-dark-blue">Your informations</h4>
            <h4 className="text-grey">{name} ({email})</h4>
            <h4 className="text-grey">{company} ({phone})</h4>
            {/* <h4 className="text-grey">{date.toLocaleDateString()} at {time} ({timezone})</h4> */}
            <Row className="animated fadeInUp delay-2s pt-5 mt-5">
                <Col xs="12" sm="10" md="6" lg="5" xl="4" className="pl-md-0">
                    <Link to="/about" className={`mt-1 btn button-project-2 pt-2 pb-2 pl-5 pr-5 text-white w-100`}>See more about us</Link>
                </Col>
            </Row>
        </Fade>
    );
};

export default Result;
