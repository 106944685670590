import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

class Case2 extends React.Component {
    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5 text-dark-blue case-wrapper">
                <Row>
                    <Col xs="12" sm="12" lg="12" xl="12">
                        <h2 className="display-4 font-weight-bold animated slideInLeft">Kinological Community of Republic of Srpska</h2>
                        <h5 className="pt-3 pt-md-5 animated slideInLeft">This project required the creation of an interlinked system that allows for users to enter various international dog competitions, as well as allowing for classification throughout the system. Such a project required a user-friendly interface, an internationalized system, as well as snappy data management, which was achieved using a technology stack of React on the frontend and Laravel/PHP with MariaDB on the backend.</h5>
                        <h5 className="pt-3 animated slideInLeft">We completed the project from start to finish, with special attention to testing, and making the end-product maintainable for future features, as we still maintain this application.</h5>
                        <h5 className="pt-3 animated slideInLeft">This application is now used in the national Kinological Community as well as being integrated with various international competitions.</h5>
                        <a href="https://kinoloskisavezksrs.com/" target="_blank" rel="noopener noreferrer" className={`mr-5 pt-5 menu-item text-light-blue animated slideInLeft`}><h5 className="font-weight-bold">Visit website <img src="/images/play-light-blue.svg" alt="play" className="ml-1" /></h5></a>
                    </Col>
                </Row>
                <Row>
                    <Fade bottom>
                        <Col xs="12" sm="12" md="12" className="mt-5 pt-5">
                            <img src="/cases/case2/first.jpg" alt="first" className="shadow" />
                        </Col>
                        <Row className="p-3">
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case2/second.jpg" alt="second" className="shadow" />
                            </Col>
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case2/third.jpg" alt="third" className="shadow" />
                            </Col>
                        </Row>
                    </Fade>
                </Row>
            </Container>
        );
    }
}

export default Case2;