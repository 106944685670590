import "./index.scss";
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import WorksSlider from './WorksSlider';

class Works extends React.Component {
    componentDidMount() {
        document.body.className = "body-works";
    }

    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5 ">
                <Row className="overflow-hidden">
                    <Col xs="12" sm="12" md="10" lg="12" xl="12" className="pt-2 pt-lg-4">
                        <Fade bottom>
                            <h2 className="mb-5 mb-lg-0 pb-5 display-4 font-weight-bold text-dark-blue">Latest projects</h2>
                        </Fade>
                        <WorksSlider />
                    </Col>
                </Row>
                <Row className="pt-lg-5 mt-lg-5 pb-lg-5">
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pt-2 pt-lg-4">
                        <Fade bottom>
                            <h2 className="pb-5 display-4 font-weight-bold text-dark-blue">Brands that trust us</h2>
                        </Fade>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pt-md-3">
                            <Row className="brands">
                                <Col xs="6" sm="6" md="4" lg="3" xl="3" className="pl-0 pt-lg-0 pl-0 mr-md-3 mr-md-5">
                                    <Fade bottom>
                                        <img src="/images/brands/jsguru.png" alt="jsguru" />
                                    </Fade>
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3" xl="3" className="pt-1 pl-4 pl-md-0 pt-lg-0 pl-0 mr-md-5">
                                    <Fade bottom>
                                        <img src="/images/brands/codexity.png" alt="codexity" />
                                    </Fade>
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3" xl="3" className="pt-4 pt-lg-0 pl-0 mr-md-3">
                                    <Fade bottom>
                                        <img src="/images/brands/pressclip.png" alt="pressclip" />
                                    </Fade>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Works;