import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

const menuItems = [{
    url: '/home',
    displayName: 'Home'
}, {
    url: '/about',
    displayName: 'About Us'
}, {
    url: '/works',
    displayName: 'Works'
}, {
    url: '/what-we-do',
    displayName: 'What We Do'
}, {
    url: '/process',
    displayName: 'Process'
}, {
    url: '/contact',
    displayName: 'Contact'
}];

class Menu extends React.Component {
    renderPhoneMenu = (pathname, onClick) => {
        return (
            <>
                <Col xs="6" className="text-right w-100 d-md-none pr-4 pt-2">
                    <img src="/images/close-darkblue.svg" alt="close" className="menu-icon" onClick={onClick} />
                </Col>
                <Col xs="12" className="align-items-left ml-4 d-md-none" onClick={onClick}>
                    {menuItems.map(menuItem => (
                        <Row className="pt-4 w-100" key={menuItem.url} onClick={() => this.props.history.push(menuItem.url)}>
                            <span
                                className={`menu-item ${pathname === menuItem.url ? "text-light-blue" : "text-dark-blue"}`}>
                                {menuItem.displayName}
                            </span>
                        </Row>
                    ))}
                </Col>
            </>
        );
    }

    renderNormalMenu = (pathname, onClick) => {
        return (
            <Col className="d-none d-md-block pr-4 pt-2" xl="5" lg="6" sm="8" onClick={onClick}>
                <div className="text-center d-table float-right w-100">
                    {menuItems.map(menuItem => (
                        <Link
                            to={menuItem.url}
                            key={menuItem.url}
                            className={`menu-item ${pathname === menuItem.url ? "text-light-blue" : "text-dark-blue"}`}>
                            {menuItem.displayName}
                        </Link>
                    ))}
                    <img src="/images/close-darkblue.svg" alt="close" className="menu-icon" onClick={onClick} />
                </div>
            </Col>
        );
    }

    render() {
        return (
            <>
                {this.renderNormalMenu(this.props.pathname, this.props.onClick)}
                {this.renderPhoneMenu(this.props.pathname, this.props.onClick)}
            </>
        );
    }
};

Menu.propTypes = {
    pathname: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default withRouter(Menu);