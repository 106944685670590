import React from "react";
import { withRouter } from 'react-router-dom';

import Entry from "../Entry";

const STORAGE_KEY = "entry_shown";

const requireEntry = ChildComponent => {
    class ComposedComponent extends React.Component {
        render() {
            if (!sessionStorage.getItem(STORAGE_KEY)) {
                return <Entry route={this.props.history.pathname} storageKey={STORAGE_KEY} {...this.props} />;
            } else {
                return <ChildComponent {...this.props} />;
            }
        }
    }

    return withRouter(ComposedComponent);
};

export default requireEntry;
