import React from "react";
import PropTypes from 'prop-types';

import { Row } from 'reactstrap';

const StepBody = ({ text, isRight, isFirst }) => {
    return (
        <Row className="pb-5 pr-3 pl-2 pl-md-0 pr-md-0">
            <h4 className={`pl-sm-5 pr-sm-5 ${isRight ? "pt-5" : ""} ${!isFirst ? "pt-4" : ""}`}>{text}</h4>
        </Row>
    );
};

StepBody.propTypes = {
    text: PropTypes.string.isRequired,
    isRight: PropTypes.bool,
    isFirst: PropTypes.bool
}

StepBody.defaultProps = {
    isRight: false,
    isFirst: false
}

export default StepBody;
