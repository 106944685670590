import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

class FadeOutOnScroll extends React.Component {
    wrapperRef = React.createRef();

    handleScroll = () => {

        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;

        if (windowBottom >= docHeight) {
            document.body.className = "animated fadeOut fast";

            this.timeout = setTimeout(() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.props.history.push(this.props.navigateTo);
                // this.props.onBottomReached();
            }, 500);
        }
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            // this.wrapperRef.current.classList.add('margin-bottom-50');
            void this.wrapperRef.current.offsetWidth;
            document.addEventListener('scroll', this.handleScroll);
        }, 2000);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
        clearTimeout(this.timeout);
    }

    render() {
        return (
            <div ref={this.wrapperRef} className={`fade-out-wrapper ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}

FadeOutOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    navigateTo: PropTypes.string.isRequired,
    className: PropTypes.string
}

FadeOutOnScroll.defaultProps = {
    className: ''
}

export default withRouter(FadeOutOnScroll);