import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars';

import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Works from "./Works";
import WhatWeDo from "./WhatWeDo";
import Process from "./Process";
import Contact from "./Contact";
import CaseStudy from "./CaseStudy";
import Project from "./Home/Project";
import ConsultationBooker from "./Consultation";

import { requireEntry } from "./Common";
import history from "../history";

const App = () => {
  return (
    // <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%">
    <Router history={history}>
      <div>
        <Header />
        <Switch>
          <Route path="/home" exact component={requireEntry(Home)} />
          <Route path="/about" exact component={requireEntry(AboutUs)} />
          <Route path="/works" exact component={requireEntry(Works)} />
          <Route path="/what-we-do" exact component={requireEntry(WhatWeDo)} />
          <Route path="/process" exact component={requireEntry(Process)} />
          <Route path="/contact" exact component={requireEntry(Contact)} />
          <Route path="/case/:id" exact component={requireEntry(CaseStudy)} />
          <Route path="/project/:id" exact component={requireEntry(Project)} />
          <Route path="/consultation" exact component={requireEntry(ConsultationBooker)} />
          <Redirect from="/" to="/home" />
        </Switch>
        <Footer />
      </div>
    </Router>
    // </Scrollbars>
  );
};

export default App;
