import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

class Case1 extends React.Component {
    render() {
        return (
            <Container className="pt-3 pt-md-5 mt-md-5 text-dark-blue case-wrapper">
                <Row>
                    <Col xs="12" sm="12" lg="12" xl="12">
                        <h2 className="display-4 font-weight-bold animated slideInLeft">Lectorical Community of Republic of Srpska</h2>
                        <h5 className="pt-3 pt-md-5 animated slideInLeft">Creating an app for the Lectorical Community required a simple-design approach as well as for the user interface, but also for the database construction and API REST services. We created a freely-accessible front news and updates board, which was updated through a CMS solution for the administrators of the Community. For this we wrote the app in React for the frontend and Laravel/Voyager for the backend and CMS side, all together with a MySQL database.</h5>
                        <h5 className="pt-3 animated slideInLeft">The development was done from start to finish, and the app is still maintained by us.</h5>
                        <h5 className="pt-3 animated slideInLeft">The app still remains in high use, and services as a news and updates panel for the sympathizers of the society, and the potential members.</h5>
                        <a href="http://udruzenjelektorars.com/home" target="_blank" rel="noopener noreferrer" className={`mr-5 pt-5 menu-item text-light-blue animated slideInLeft`}><h5 className="font-weight-bold">Visit website <img src="/images/play-light-blue.svg" alt="play" className="ml-1" /></h5></a>
                    </Col>
                </Row>
                <Row>
                    <Fade bottom>
                        <Col xs="12" sm="12" md="12" className="mt-5 pt-5">
                            <img src="/cases/case1/first.png" alt="first" className="shadow" />
                        </Col>
                        <Row className="p-3">
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case1/second.png" alt="second" className="shadow" />
                            </Col>
                            <Col xs="12" sm="12" md="6" className="mt-5">
                                <img src="/cases/case1/third.png" alt="third" className="shadow" />
                            </Col>
                        </Row>
                    </Fade>
                </Row>
            </Container>
        );
    }
}

export default Case1;