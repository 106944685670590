import React from "react";
import PropTypes from 'prop-types';

import { Row, Col } from "reactstrap";

const StepTitle = ({ title, position, className, isFirst, isLast }) => {
  if (position === "left") {
    return (
      <Row>
        <Col xs="1">
          <div className={`rounded-square-left mr-5 ${className}`} />
        </Col>
        <Col xs="10">
          <h2 className={`title-text pb-4 ${isFirst ? "first-title" : "mt-1 mt-md-3 pt-4"}`}>{title}</h2>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="text-right">
        <Col xs="10" md="11">
          <h2 className={`text-right title-text ${isLast ? "mt-1 mt-md-4 pt-md-3 margin-left-minus" : "mt-md-2 pt-md-4"}`}>{title}</h2>
        </Col>
        <Col xs="2" md="1">
          <div className={`rounded-square-right ${className}`} />
        </Col>
      </Row>
    );
  }
};

StepTitle.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

StepTitle.defaultProps = {
  position: "left",
  className: "",
  isFirst: false,
  isLast: false,
}


export default StepTitle;
