import React from 'react';
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Input } from 'reactstrap';

class ContactForm extends React.Component {
    state = {
        name: "",
        email: "",
        subject: "",
        message: "",
    };


    clearState = () => {
        this.setState({
            name: "",
            email: "",
            subject: "",
            message: "",
        });
    };

    handleSubmit = (evt) => {
        evt.preventDefault();

        this.props.onSubmit(this.state);
        this.clearState();
    }

    render() {
        const { name, email, subject, message } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} className="contact-form">
                <FormGroup row className="mb-4">
                    <Col sm="12" md="6">
                        <Input type="text" name="name" id="name" placeholder="Name" value={name} onChange={({ target }) => this.setState({ name: target.value })} required />
                    </Col>
                </FormGroup>
                <FormGroup row className="mb-4">
                    <Col sm="12" md="6">
                        <Input type="email" name="email" id="email" placeholder="E-mail" value={email} onChange={({ target }) => this.setState({ email: target.value })} required />
                    </Col>
                </FormGroup>
                <FormGroup row className="mb-4">
                    <Col sm="12" md="6">
                        <Input type="text" name="subject" id="subject" placeholder="Subject" value={subject} onChange={({ target }) => this.setState({ subject: target.value })} required />
                    </Col>
                </FormGroup>
                <FormGroup row className="mb-4">
                    <Col sm="12" md="8">
                        <Input type="textarea" name="message" id="message" placeholder="Message" rows="11" value={message} onChange={({ target }) => this.setState({ message: target.value })} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm="12" md="2">
                        <Button type="submit" className="btn-submit">Send</Button>
                    </Col>
                </FormGroup>
            </Form>
        );
    }
}

ContactForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}


export default ContactForm;