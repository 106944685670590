import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import React from 'react';
import { render } from 'react-dom';
import Root from './Root';
import App from './components/App';

import './styles/custom.scss';

render(
    <Root>
        <App />
    </Root>,
    document.querySelector('#root')
);