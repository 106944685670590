import React from 'react';
import Slider from "react-slick";
import TeamMember from './TeamMember';
import Fade from 'react-reveal/Fade';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TEAM_MEMBERS = [
    {
        image1: '/images/portraits/aleksa_lightBlue.svg',
        image2: '/images/portraits/aleksa-real.png',
        fullName: 'Aleksa Marinković',
        title: 'Business Development Manager',
        socialLinks: [{ displayName: 'LinkedIn', url: 'https://www.linkedin.com/in/aleksa-marinkovic-2b4446132/' }]
    },
    {
        image1: '/images/portraits/nikola_lightBlue.svg',
        image2: '/images/portraits/nikola-real.png',
        fullName: 'Nikola Mijović',
        title: 'Frontend Developer',
        socialLinks: [{ displayName: 'LinkedIn', url: 'https://www.linkedin.com/in/nikola-mijovic/' }]
    },
    {
        image1: '/images/portraits/kljaja_lightBlue.svg',
        image2: '/images/portraits/boris-real.png',
        fullName: 'Boris Kljajić',
        title: 'Full Stack Developer',
        socialLinks: [{ displayName: 'LinkedIn', url: 'https://www.linkedin.com/in/boris-kljaji%C4%87-354161180/' }]
    },
    {
        image1: '/images/portraits/mladjo_lightBlue.svg',
        image2: '/images/portraits/mladjo-real.png',
        fullName: 'Mladen Despot',
        title: 'Full Stack Developer',
        socialLinks: [{ displayName: 'LinkedIn', url: 'https://www.linkedin.com/in/mladen-despot-60b1b414a/' }]
    }
];

class TeamMembersSlider extends React.Component {
    renderMembers() {
        return TEAM_MEMBERS.map(({ image1, image2, fullName, title, socialLinks }) => <TeamMember className="m-3" key={fullName} image1={image1} image2={image2} fullName={fullName} title={title} socialLinks={socialLinks} />)
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            autoplay: true,
            dots: false,
            arrows: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    centerMode: false
                }
            }]
        };

        return (
            <Fade bottom>
                <Slider {...settings} className="text-dark-blue team-members-slider">
                    {this.renderMembers()}
                </Slider>
            </Fade>
        );
    }
}

export default TeamMembersSlider;