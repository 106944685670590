import "./animations.scss";
import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const HEAD_TEXTS = [
  "software development",
  "web <br/> development",
  "mobile development"
];

class Home extends React.Component {
  textRef = React.createRef();
  currentIndex = 0;
  textInterval = null;
  textTimeout = null;

  changeText = () => {
    const headText = HEAD_TEXTS[++this.currentIndex % HEAD_TEXTS.length];

    if (this.textRef.current) {
      this.textRef.current.classList.remove("fadeIn");
      void this.textRef.current.offsetWidth;
      this.textRef.current.classList.add("fadeOut");
      this.textTimeout = setTimeout(() => {
        if (this.textRef.current) {
          this.textRef.current.classList.remove("fadeOut");
          void this.textRef.current.offsetWidth;
          this.textRef.current.classList.add("fadeIn");
          this.textRef.current.innerHTML = headText;
        }
      }, 500);
    }
  };

  componentDidMount() {
    document.body.className = "body-home";

    this.textInterval = setInterval(this.changeText, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.textInterval);
    clearTimeout(this.textTimeout);
  }

  render() {
    const headText = HEAD_TEXTS[0];

    return (
      <>
        <div className="body-background m-0 p-0 animated slideInDown fast" />
        <Container className="pt-3 pt-md-5 mt-md-3 pb-5">
          <Row className="text-white pb-5 mb-2">
            <Col
              xs="12"
              sm="12"
              md="6"
              lg="5"
              xl="5"
              className="my-auto pl-md-0 pr-md-0 pr-md-5 animated fadeInUp"
            >
              <h2 className="display-5 font-weight-bold">
                We are in the business of awesome{" "}
                <span
                  className="text-light-blue font-weight-bold animated"
                  ref={this.textRef}
                >
                  {headText}
                </span>
              </h2>
              <h5 className="home-intro-text">
                Softlife designs and builds beautiful world-class iOS, Android
                and Web apps for start-ups and big brands.
              </h5>
              <Link
                to="/contact"
                className={`mt-3 btn button-head pt-2 pb-2 pl-5 pr-5 text-light-blue`}
              >
                Get in touch
              </Link>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="6"
              lg="7"
              xl="7"
              className="pt-5 pt-md-0 pr-md-0 pl-md-0 animated fadeInUp slow home-move"
            >
              <img
                src="/images/home.svg"
                alt="home"
                // className="move move-violet-cogwheels"
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="5"
              lg="4"
              xl="3"
              className="pl-md-0 animated fadeInLeft delay-2s"
            >
              <Link
                to="/project/1"
                className="mt-5 mt-lg-0 btn button-home pt-2 pb-2 text-white w-100 animated pulse infinite"
              >
                Click for featured projects
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Home;
