import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const Testimonial = ({
    image, fullName, title, className, text
}) => (
        <div className={`testimonial-wrapper ${className}`}>
            <Row className="pt-md-4 pb-md-4 pl-md-4 pr-md-4 ml-md-2 mr-md-2 shadow h-100">
                <Col xs="12" sm="12" lg="4" xl="4" className="pt-3 pb-3 pt-md-0 pb-md-0">
                    <img src={image} alt={fullName} className="testimonial-image mb-3 mx-auto shadow" />
                    <h5 className="team-member-fullname d-block mb-2 text-center font-weight-bold">{fullName}</h5>
                    <span className="team-member-title d-block mb-3 text-center">{title}</span>
                </Col>
                <Col xs="12" sm="12" lg="8" xl="8" className="align-items-center d-lg-flex testimonial-text">
                    <Row className="w-100">
                        <Col>
                            <h5>{text}</h5>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

Testimonial.propTypes = {
    image: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string
}

Testimonial.defaultProps = {
    className: ''
}

export default Testimonial;