import "./index.scss";
import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Step from './Step';

const STEPS = [
  {
    position: "left",
    title: "Technology Consulting",
    text: "Our technical experts perform a Through evaluation of you projects needs and available technology stacks to help you pick the most appropriate option."
  },
  {
    position: "right",
    title: "Discovery and Planning",
    text: "We help define the project strategy and roadmap, clarify requirements and prepare the required documentation to ensure you are fixing the right problems and optimizing the right actions.",
    className: "pt-5 margin-2-up"
  },
  {
    position: "left",
    title: "Software Prototyping",
    text: "A proof-of-concept helps evaluate the feasibility of your concept for real-world implementation and assess the potential of your idea with minimum resources consumed.",
    className: "pt-5 margin-2-up"
  },
  {
    position: "right",
    title: "Agile Methodology",
    text: "Our Scrum Masters are well trained to plan and develop applications based on the agile framework which includes planning story points, sprints, etc. This helps our clients ensure that development is going in the right direction.",
    className: "last-step-right pt-5 margin-2-up"
  }
]

class Process extends React.Component {
  componentDidMount() {
    document.body.className = "body-process";
  }

  renderSteps() {
    return STEPS.map((step, index) => {
      const isFirst = index === 0,
        isLast = index === (STEPS.length - 1);

      return <Step key={index} {...step} isFirst={isFirst} isLast={isLast} />
    });
  }

  render() {
    return (
      <Container className="pt-3 pt-md-5 mt-md-5 process-wrapper">
        <div className="mb-5 pb-5">
          <Col xs={{ size: '12' }} md={{ size: '8' }}>
            <h1 className="text-white header-text display-4 animated slideInLeft">Navigating your <br /> <span className="text-light-blue">Ideas</span> into <span className="text-light-blue">Products</span></h1>
          </Col>
        </div>

        <Container className="pl-5 pr-md-5 mt-5 pt-5 text-white animated fadeIn">
          {this.renderSteps()}
          <Row className="justify-content-end pr-5 pt-5">
            <Fade bottom>
              <div className="intro-process-wrapper">
                <Link to="/consultation" className="btn btn-intro-process">Interested to work with us?</Link>
              </div>
            </Fade>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Process;
