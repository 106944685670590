import "./index.scss";
import React from 'react';
import { Container, Col, Row } from 'reactstrap'
import { withRouter, Link } from 'react-router-dom';

import Menu from "./Menu";

class Header extends React.Component {
    state = {
        collapsed: true
    };

    renderContactButton(pathname) {
        const classColorPhone = pathname === '/works' || pathname === '/what-we-do' || pathname === '/consultation' || pathname.startsWith('/case') ? 'text-dark-blue' : 'text-light-blue';

        return (
            <div className="header-contact-button-wrapper">
                <Link to="/contact" className={`float-right mr-5 menu-item d-none d-md-block btn button-like pt-2 pb-2 pl-4 pr-4 text-light-blue`}>Get in touch</Link>
                <Link to="/contact" className={`float-right mr-5 pt-1 d-md-none menu-item ${classColorPhone}`}>Let's talk</Link>
            </div>
        );
    }

    onClickHandler = () => {
        this.setState({ collapsed: true });
    }

    renderMenu() {
        const { pathname } = this.props.location || "/";

        if (!this.state.collapsed) {
            document.body.classList.add('no-overflow');

            return (
                <Menu pathname={pathname} onClick={this.onClickHandler} />
            );
        } else {
            document.body.classList.remove('no-overflow');

            return (
                <Col md="6" xs="7" className="pr-4 pt-2">
                    <img src={`/images/${pathname !== '/works' && pathname !== '/what-we-do' && pathname !== '/consultation' && !pathname.startsWith('/case') ? 'hamburger-white.svg' : 'hamburger-darkblue.svg'}`} alt="ham" className="menu-icon menu-icon-collapsed float-right" onClick={(evt) => { evt.stopPropagation(); this.setState({ collapsed: !this.state.collapsed }) }} />
                    {this.renderContactButton(pathname)}
                </Col>
            );
        }
    }

    render() {
        const { pathname } = this.props.location || "/";

        return (
            <header>
                <Container fluid className={`animated slideInDown ${!this.state.collapsed ? "p-0 full-height fixed-top" : "p-0"}`} onClick={this.onClickHandler}>
                    <Container fluid className={`pt-4 pb-4 ${this.state.collapsed ? "" : "bg-white animated slideInDown fast"}`}>
                        <Row>
                            <Col className="pl-4 d-none d-md-block">
                                <Link to="/home">
                                    <img src={`/images/logo/${this.state.collapsed && (pathname !== '/works' && pathname !== '/what-we-do' && pathname !== '/consultation' && !pathname.startsWith('/case')) ? 'white.svg' : 'darkblue.svg'}`} alt="logo" onClick={this.onClickHandler} />
                                </Link>
                            </Col>
                            <Col xs="5" md="6" className="pl-4 d-md-none">
                                <Link to="/home">
                                    <img src={`/images/logo/${this.state.collapsed && (pathname !== '/works' && pathname !== '/what-we-do' && pathname !== '/consultation' && !pathname.startsWith('/case')) ? 'white-small.svg' : 'darkblue-small.svg'}`} alt="logo" onClick={this.onClickHandler} />
                                </Link>
                            </Col>
                            {this.renderMenu()}
                        </Row>
                    </Container>
                </Container>
            </header>
        );
    }
}

export default withRouter(Header);