import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Project1 extends React.Component {
    componentDidMount() {
        document.body.className = "project-1";
    }

    render() {
        return (
            <Container className="project-1-wrapper pb-5 pt-3 pt-md-5 mt-md-5 animated">
                <Row className="text-white pr-0 mr-0">
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="my-auto pl-md-5 pl-my-xl-0 pr-md-5 animated fadeInUp">
                        <h2 className="home-project-title" style={{maxWidth: '500px'}}>eClipLive</h2>
                        <h4 className="pt-3 pt-md-5 home-project-description" style={{maxWidth: '500px'}}>Ongoing development from the start, creation of a feature-filled system for the analysis of the movements, news and information in everyday international media, with a complex filtering system.</h4>
                        <Link to="/case/3" className={`mr-5 pt-5 menu-item text-white show-normal`}><h5 className="home-project-link">View case study <img src="/images/play-white.svg" alt="play" className="ml-1" /></h5></Link>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="pt-4 pt-md-0 pr-0 animated fadeInRight fast">
                        <img src="/cases/case3/slider-3-o.png" alt="proj1" className="border-15"/>
                    </Col>
                    <Col xs="12" className="show-phone pl-5">
                        <Link to="/case/3" className={`mr-5 pt-5 menu-item text-white`}><h5 className="home-project-link">View case study <img src="/images/play-white.svg" alt="play" className="ml-1" /></h5></Link>
                    </Col>
                    <Col xs="12" sm="12" md="4" className="pt-5 mt-md-5 pl-md-5 pl-my-xl-0 animated fadeInLeft delay-2s">
                        <Link to="/project/2" className="mt-5 mt-lg-0 btn button-home pt-2 pb-2 text-white w-100 animated pulse infinite">Click for next project</Link>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Project1;