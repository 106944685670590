import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Project1 extends React.Component {
    componentDidMount() {
        document.body.className = "project-2";
    }

    render() {
        return (
            <Container className="project-2-wrapper pt-3 pt-md-4 mt-md-5 pb-5">
                <Row className="text-dark-blue pr-0 mr-0">
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="my-auto pl-md-0 pr-md-5 animated fadeInUp">
                        <h2 className="home-project-title">Kinological Community of Republic of Srpska</h2>
                        <h4 className="pt-3 pt-md-5 home-project-description">Start to end Development and maintenance, creation of a large database for a competition system that serves to classify dogs and prepare international contents. Internationalisation system creation.</h4>
                        <Link to="/case/2" className={`mr-5 pt-5 menu-item text-white show-normal`}><h5 className="home-project-link">View case study <img src="/images/play-white.svg" alt="play" className="ml-1" /></h5></Link>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="pt-4 pl-md-5 pt-md-0 pr-0 animated fadeInRight fast project-2-image-wrapper">
                        <img src="/cases/case2/slider-2-o.png" alt="proj2" className="border-15 project-2-image" />
                    </Col>
                    <Col xs="12" className="show-phone pl-5">
                        <Link to="/case/2" className={`mr-5 pt-5 menu-item text-light-blue`}><h5 className="home-project-link">View case study <img src="/images/play-light-blue.svg" alt="play" className="ml-1" /></h5></Link>
                    </Col>
                    <Col xs="12" sm="10" md="6" lg="5" xl="4" className="pl-md-0 animated fadeInLeft delay-1s">
                        <Link to="/consultation" className="mt-5 btn button-project-2 pt-2 pb-2 text-white w-100 animated delay-2s pulse infinite">Interested to work with us?</Link>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Project1;