import "./index.scss";
import React from 'react';
import { withRouter } from 'react-router-dom';

class Entry extends React.Component {
    componentDidMount() {
        document.body.className = "body-entry";

        setTimeout(() => {
            sessionStorage.setItem(this.props.storageKey, true);
            this.props.history.push(this.props.route);
        }, 3000);
    }

    render() {
        return (
            <img src="/images/entry.svg" alt="entry" className="entry-img" />
        );
    }

}

export default withRouter(Entry);