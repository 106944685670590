import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';

import Work from './Work';

const WORKS = [
    {
        image: '/cases/case3/slider-3.png',
        title: 'eClipLive',
        text: 'Ongoing development from the start, creation of a feature-filled system for the analysis of the movements, news and information in everyday international media, with a complex filtering system.',
        link: '/case/3',
        className: 'background-accent-2',
        titleClass: 'text-dark-blue',
        imageClass: 'my-auto'
    },
    {
        image: '/cases/case1/slider-1.png',
        title: 'Lectorical Community of Republic of Srpska',
        text: 'Start to end development and maintenance, creation of a CMS system for the administration of publishing news and information, administration of members.',
        link: '/case/1',
        className: 'background-dark-blue',
        titleClass: 'text-light-blue',
        imageClass: 'my-auto'
    },
    {
        image: '/cases/case2/slider-2.png',
        title: 'Kinological Community of Republic of Srpska',
        text: 'Start to end Development and maintenance, creation of a large database for a competition system that serves to classify dogs and prepare international contents. Internationalisation system creation.',
        link: '/case/2',
        className: 'background-accent-2',
        titleClass: 'text-dark-blue',
        imageClass: 'pt-md-3'
    }
]

class WorksSlider extends React.Component {
    renderTestimonials() {
        return WORKS.map(({ link, image, title, text, className, titleClass, imageClass }) => <Work className={`m-3 ${className}`} key={title} image={image} title={title} text={text} link={link} titleClass={titleClass} imageClass={imageClass}/>)
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            dots: false,
            arrows: true,
            autoplay: false,
            responsive: [{
                breakpoint: 991,
                settings: {
                    centerMode: false
                }
            }]
        };

        return (
            <Fade bottom>
                <Slider {...settings} className="text-dark-blue works-slider">
                    {this.renderTestimonials()}
                </Slider>
            </Fade>
        );
    }
}

export default WorksSlider;