import { FETCH_BOOKED_SLOTS } from "../actions/types";

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
    case FETCH_BOOKED_SLOTS:
      const { date, bookedSlots } = payload;

      return { ...state, [date]: bookedSlots };
    default:
      return state;
  }
};
