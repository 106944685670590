import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Work = ({
    title, text, link, image, className, titleClass, imageClass
}) => (
        <div className={`work-wrapper h-100 position-relative m-3 m-lg-0`}>
            <Row className={`ml-md-0 mr-2 mr-md-0 h-100 ${className} position-absolute work-background`}></Row>
            <Row className="p-2 p-md-4 ml-2 mr-2 ml-md-0 mr-md-0 h-100 w-100 text-white">
                <Col xs="12" sm="12" lg="8" xl="8" className="pt-5 pb-3 pt-md-5 pb-md-0 my-auto text-center">
                    <h4 className={`work-title d-block mb-5 text-left font-weight-bold ${titleClass}`}>{title}</h4>
                    <h5 className="work-text d-block mb-3 text-left">{text}</h5>
                    <Link to={link} className={`text-white`}><h5 className="font-weight-bold mt-5 text-left">View case study <img src="/images/play-white.svg" alt="play" className="ml-2" /></h5></Link>
                </Col>
                <Col xs="12" sm="12" lg="4" xl="4" className="d-lg-flex">
                    <Row className="w-100" >
                        <Col xs="12" sm="12" className={`${imageClass}`}style={{ textAlign: 'center', maxHeight: '340px' }}>
                            <img src={image} alt="img" className="work-image" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

Work.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    imageClass: PropTypes.string,
    className: PropTypes.string
}

Work.defaultProps = {
    className: '',
    imageClass: ''
}

export default Work;