import React from 'react';
import Slider from "react-slick";
import Testimonial from './Testimonial';
import Fade from 'react-reveal/Fade';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TESTIMONIALS = [
    {
        image: '/testimonials/js-guru.jpg',
        fullName: 'Srdjan Sukara',
        title: 'Sales and Marketing manager at JSGuru',
        text: 'We chose Softlife to be our partner on a project and we can happily say that this was a great decision. Not only that they exceeded our expectations, but we have a business partner that we can truly trust!'
    },
    {
        image: '/testimonials/kinological.jpg',
        fullName: 'Bojan Čenić',
        title: 'Kinological Community of Republic of Srpska',
        text: 'We are more than happy to say that Softlife was an excellent choice for us. They are a talented and experienced team, very much focused on the quality of their work. The communication was outstanding, we felt like we were part of the team, which is really nice and helped build trust. We really felt like we were more than just a client to them. Definitely, we choose Softlife our long term partner.'
    },
    {
        image: '/testimonials/ecliplive.jpg',
        fullName: 'Vladimir Savić',
        title: 'eClipLive',
        text: 'Softlife was a great choice for our product, as they have succeeded in realizing all of our ideas into fast and cutting-edge modern platforms. We plan to continue working together with Softlife in order to grow our product beyond it\'s original form, and expand it\'s capabilities'
    },
];

class TestimonialsSlider extends React.Component {
    renderTestimonials() {
        return TESTIMONIALS.map(({ fullName, image, title, text }) => <Testimonial className="m-3" key={fullName} fullName={fullName} image={image} title={title} text={text} />)
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            dots: false,
            arrows: true,
            autoplay: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    centerMode: false
                }
            }]
        };

        return (
            <Fade bottom>
                <Slider {...settings} className="text-dark-blue testimonials-slider">
                    {this.renderTestimonials()}
                </Slider>
            </Fade>
        );
    }
}

export default TestimonialsSlider;