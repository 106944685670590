import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const renderTeamMemberDetailsDesktop = ({ fullName, title, socialLinks }) => (
    <Col xs="12" sm="12" lg="7" xl="7" className="align-items-center team-member-desktop d-none d-lg-flex shadow">
        <Row className="w-100">
            <Col className="offset-2">
                <span className="team-member-fullname d-block mb-2">{fullName}</span>
                <span className="team-member-title d-block mb-4">{title}</span>
                {
                    socialLinks.map(socialLink =>
                        <a href={socialLink.url} key={socialLink.displayName} target="_blank" rel="noopener noreferrer" className={`mr-5 pb-2 d-block text-light-blue`}><h5 className="font-weight-bold d-inline-flex">{socialLink.displayName}<img src="/images/play-light-blue.svg" alt="play" className="ml-2" /></h5></a>
                    )
                }
            </Col>
        </Row>
    </Col>
)

const renderTeamMemberDetailsMobile = ({ fullName, title, socialLinks }) => (
    <Col xs="12" sm="12" lg="12" xl="12" className="align-items-left team-member-mobile d-lg-none">
        <Row>
            <Col className="pt-4 pl-5 pr-5 pr-md-3 team-member-mobile-border shadow">
                <span className="team-member-fullname d-block mb-2 font-weight-bold">{fullName}</span>
                <span className="team-member-title d-block mb-3">{title}</span>
                {
                    socialLinks.map(socialLink =>
                        <a href={socialLink.url} key={socialLink.displayName} target="_blank" rel="noopener noreferrer" className={`mr-5 pb-2 d-block text-light-blue`}><h5 className="font-weight-bold d-inline-flex">{socialLink.displayName}<img src="/images/play-light-blue.svg" alt="play" className="ml-2" /></h5></a>
                    )
                }
            </Col>
        </Row>
    </Col>
)

const TeamMember = ({
    image1, image2, fullName, title, socialLinks, className
}) => (
        <div className={`team-member-wrapper ${className}`}>
            <Row className="pt-md-1 pb-md-1 ml-md-3 mr-md-3">
                <Col xs="12" sm="12" lg="5" xl="5" className="pr-0 pl-0 team-member-image-wrapper">
                    <img src={image1} alt={fullName} className="team-member-image" />
                    <img src={image2} alt={fullName} className="team-member-image img-top" />
                </Col>

                <>
                    {renderTeamMemberDetailsDesktop({ fullName, title, socialLinks })}
                    {renderTeamMemberDetailsMobile({ fullName, title, socialLinks })}
                </>


            </Row>
        </div>
    );

TeamMember.propTypes = {
    image1: PropTypes.string.isRequired,
    image2: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired
    })),
    className: PropTypes.string
}

TeamMember.defaultProps = {
    socialLinks: [],
    className: ''
}

export default TeamMember;