import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import ConsultationBanner from '../Consultation/ConsultationBanner';

const Footer = () => (
    <footer>
         <Fade bottom>
            <ConsultationBanner />
        </Fade>
        <Container className="mt-5">
            <Row style={{ fontSize: '16px' }}>
                <Col xs="12" sm="12" lg="6" xl="6" className="pl-md-0">
                    <span className="footer-copyright-notice">&copy; Softlife 2019. All rights reserved.</span>
                </Col>
                <Col xs="12" sm="12" lg="6" xl="6" className="pt-3 pt-md-0 text-lg-right text-xl-right">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="https://www.facebook.com/softlifeyourlifeincode" target="_blank" rel="noopener noreferrer" className="footer-social-link">Facebook</a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">Twitter</a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.linkedin.com/company/softlife-software" target="_blank" rel="noopener noreferrer" className="footer-social-link">LinkedIn</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;