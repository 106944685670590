import React from "react";
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import StepTitle from "./StepTitle";
import StepBody from "./StepBody";

const Step = ({ title, text, position, className, isFirst, isLast }) => {
  if (position === 'left') {
    return (
      <Row>
        <Col xs={{ size: '9', offset: 1 }} md={{ size: '8', offset: 1 }} className={`step-left title-${position} ${className} ${isFirst ? "first-step" : ""}`}>
          <Fade bottom>
            <StepTitle title={title} position={position} className={`${isFirst ? "first-bar" : "last-bar"}`} isFirst={isFirst} />
          </Fade>
        </Col>
        <Col xs={{ size: '9' }} md={{ size: '8' }} className={`step-left-bottom pl-5 pt-4 text-${position}`}>
          <Fade bottom>
            <StepBody text={text} className="ml-5" isFirst={isFirst} />
          </Fade>
        </Col>
        <div id="borderLeft"></div>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={{ size: '9', offset: 1 }} md={{ size: '8', offset: 1 }} className={`step-right ml-auto mr-5 pr-5 title-${position} ${className}`}>
          <Fade bottom>
            <StepTitle title={title} position={position} className="last-bar" isLast={isLast} />
          </Fade>
        </Col>
        <Col xs={{ size: '9', offset: 1 }} md={{ size: '8', offset: 1 }} className={`step-right-bottom ml-auto mr-5 pr-5 pt-4 text-${position} ${isLast ? "last-step" : ""}`}>
          <Fade bottom>
            <StepBody text={text} className="mr-5" isRight={true} />
          </Fade>
        </Col>
      </Row>
    );
  }
};

Step.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
}

Step.defaultProps = {
  position: "left",
  className: "",
  isFirst: false,
  isLast: false,
}

export default Step;
